.mt-10-per{
  margin-top:10% !important;
}

.mt-15-per{
  margin-top:15% !important;
}


.login-form-text-icon{
  position: absolute;
  width: 30px;
  margin-top: 9px;
}


.login-form-control{
  padding-left: 8%;
  line-height:2;
}


.article-table tr {
  cursor: pointer;
}


.article-table tr * {
  cursor: pointer;
}

.form-control:focus{
  //border-color: rgba(91, 115, 232, 0.94) !important;
  border:2px solid rgba(91, 115, 232, 0.94);
}
