
//
// Range slider
//

.irs {
  font-family: $font-family-base;
}

.irs--round{
  cursor: pointer;
  .irs-bar, .irs-to, .irs-from, .irs-single {
    background: $primary !important;
    font-size: 11px;
  }
  .irs-to, .irs-from, .irs-single{
    &:before{
      display: none;
    }
  }

  .irs-line{
    background: $light;
    border-color: $light;
  }
  .irs-grid-text{
    font-size: 11px;
    color: $gray-500;
  }
  .irs-min, .irs-max{
    color: $gray-500;
    background: var(--#{$prefix}light);
    font-size: 11px;
  }

  .irs-handle{
    border: 1px solid $primary;
    width: 6px;
    height: 16px;
    top: 29px;
    background-color: var(--#{$prefix}card-bg) !important;
    cursor: pointer;
    &:active{
      cursor: pointer;
    }
  }
}

[data-bs-theme="dark"]{
  .irs-line{
    background:  #343747;
    border-color:  #343747;
  }
}